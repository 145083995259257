import logo from '../assets/image/logo.png'
import homeBtn0 from '../assets/image/home-btn.png'
import helpBtn0 from '../assets/image/help-btn.png'
import tuto1 from '../assets/image/tuto-icon1.png'
import tuto2 from '../assets/image/tuto-icon2.png'
import tuto3 from '../assets/image/tuto-icon3.png'
import closeBtn from '../assets/image/close.png'
import elephantImg from '../assets/image/atmos_elephant.png'
import {
    gsap
} from "gsap";


//logo

const logoMark =document.querySelectorAll('#logo')

for (let i = 0; i < logoMark.length; i++) {
    const logoImg = document.createElement('img')
    logoImg.src = logo
    logoMark[i].appendChild(logoImg)
 }


// home btn

const homeSect =document.querySelectorAll('#home')

 for (let i = 0; i < homeSect.length; i++) {
    const homeBtn = document.createElement('img')
    homeBtn.src = homeBtn0
    homeSect[i].appendChild(homeBtn)
 }

// help btn



const helpSect = document.querySelectorAll('#help')
for (let i = 0; i < helpSect.length; i++) {
    const helpBtn = document.createElement('img')
    helpBtn.src = helpBtn0 
    helpSect[i].appendChild(helpBtn)
   
}

window.onload = () => {
    //elephant image
    const elephantImage = document.createElement('img')
    elephantImage.src = elephantImg
    document.getElementById('elephant').appendChild(elephantImage)
}

//tuto icon

const tutoIcon1 = document.createElement('img')
tutoIcon1.src = tuto1
document.getElementById('tuto1').appendChild(tutoIcon1)


const tutoIcon2 = document.createElement('img')
tutoIcon2.src = tuto2
document.getElementById('tuto2').appendChild(tutoIcon2)


const tutoIcon3 = document.createElement('img')
tutoIcon3.src = tuto3
document.getElementById('tuto3').appendChild(tutoIcon3)


//close
const closeBtn0 = document.createElement('img')
closeBtn0.src = closeBtn
document.getElementById('closeBtn').appendChild(closeBtn0)

//menu

const menuElem = document.querySelector('.menuElem')
const menuInd = document.querySelector('.ind')
console.log
menuElem.addEventListener('mouseenter', () => {
    menuInd.style.display = 'block'

})
menuElem.addEventListener('mouseleave', () => {
    menuInd.style.display = 'none'
})

// tutorial 
const tutorialBtn = document.querySelectorAll('#help')

const close = document.querySelector('#closeBtn')
for (let i=0;i<2;i++){
    tutorialBtn[i].addEventListener('click', () => {
        gsap.fromTo(".tutorial_menu",.6,{
            skewY:-4,
            y:20,
            display:'none',
            ease: "Expo.Out",
            opacity:0
        },{
            skewY:0,
            y:0,
            display:'block',
            opacity:1
        })
})
}
// tutorialBtn.addEventListener('click', () => {
//     const tutorialMenu = document.querySelector('.tutorial_menu')
//     tutorialMenu.style.display = 'block'
// })
close.addEventListener('click', () => {
    gsap.fromTo(".tutorial_menu",.4,{
        display:'block',
        ease: "Expo.Out",
        skewY:0,
        y:0,


    },{
       skewY:-4,
       y:100,
        display:'none',
        opacity:0
        
    })
})

const menuBtn = document.querySelector('.menu-btn')
// const menuElemSp = document.querySelectorAll('.Sp-elem')

const menuBg = document.querySelector('.menu-Sp')
const menuBtns = document.querySelectorAll('.menu-box')



// menu 
const t1 = gsap.timeline()

t1.to(menuBg, 1.2, {
    right: "0%",
    opacity:1,
    ease: "Expo.easeInOut"
   

});
t1.to(menuBtn, 1.4, {
    rotation: 45,
    ease:"Expo.out"
}, "-=1")

t1.to(menuBtns,.6,{

    backgroundColor:'white',
   

},"-=.6")

t1.fromTo(".Sp-elem",.7, {
    skewY: -4,
    y: 10,
    opacity: 0,
    ease: "Expo.inOut",
    stagger: 0.2
},
{
    opacity:1,
    skewY:0,
    y:0,
},"-=.7" )
t1.fromTo(".menu-Sp-icons",.7,{
    skewY: -4,
    y: 10,
    opacity: 0,
    ease: "Expo.inOut",
    stagger: 0.3
},{
    opacity:1,
    skewY:0,
    Y:0
},'+=0.2')

t1.reverse();

menuBtn.addEventListener('click', () => {


    // for (let i = 0; i < 4; i++) {
    //     menuBtns[i].style.background = "white"
    // }

    t1.reversed(!t1.reversed());
})
// const labelDiv = document.querySelectorAll('.point')

// for (let i = 0; i < labelDiv.length; i++) {
// if(labelDiv[i].classList.contains('visible')==true){
// gsap.to(".label",2.4,{
//     scale:1.2,
//     ease:"linear",
//     repeat:-1,
//     yoyo:true,
//     background:'rgba(0,0,0,0.1)'
// })
// }
// }