import './style.css'
import './menu'
import './blocker'

import * as THREE from 'three'
import {
	OrbitControls
} from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import {
	GLTFLoader
} from 'three/examples/jsm/loaders/GLTFLoader.js'
import {
	DRACOLoader
} from 'three/examples/jsm/loaders/DRACOLoader.js'

import {
	EffectComposer
} from 'three/examples/jsm/postprocessing/EffectComposer.js'
import {
	RenderPass
} from 'three/examples/jsm/postprocessing/RenderPass.js'

import {
	SMAAPass
} from 'three/examples/jsm/postprocessing/SMAAPass.js'
import {
	UnrealBloomPass
} from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import {
	BokehPass
} from 'three/examples/jsm/postprocessing/BokehPass.js'

import {
	Raycaster,
	LinearFilter,
	RGBAFormat,
	AdditiveBlending,
	MultiplyBlending,
	RectAreaLight,
} from 'three'
import{
    TextPlugin
} from "gsap/dist/TextPlugin";

gsap.registerPlugin(TextPlugin);

import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'

import {
	GUI
} from 'three/examples/jsm/libs/dat.gui.module.js'
import Stats from 'stats.js'

import {
	gsap
} from "gsap";


var mobile
mobile = false;
var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


// // // DebugUI

// const stats = new Stats()
// stats.showPanel(0)
// document.body.appendChild(stats.dom)

// const gui = new GUI()
const debugObject = {}

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()


//  Update all materials

const updateAllMaterials = () => {
	scene.traverse((child) => {
		if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
			child.material.envMap = environmentMap
			child.material.envMapIntensity = debugObject.envMapIntensity
			child.material.needsUpdate = true
			child.castShadow = true
			child.receiveShadow = true
		}
	})
}
/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const particleTexture = textureLoader.load('/textures/particles/cloud.png')
const emiMap = textureLoader.load('/textures/maps/emiMap.png')
const cubeTextureLoader = new THREE.CubeTextureLoader()

/**
 * Environment map
 */
const environmentMap = cubeTextureLoader.load([
	'/textures/environmentMaps/px.png',
	'/textures/environmentMaps/nx.png',
	'/textures/environmentMaps/py.png',
	'/textures/environmentMaps/ny.png',
	'/textures/environmentMaps/pz.png',
	'/textures/environmentMaps/nz.png'
])

environmentMap.encoding = THREE.sRGBEncoding

scene.background = environmentMap
scene.environment = environmentMap

debugObject.envMapIntensity = 4.64
// gui.add(debugObject, 'envMapIntensity').min(0).max(10).step(0.001).onChange(updateAllMaterials)




//overlay
// const overlayGeometry = new THREE.PlaneBufferGeometry(2, 2, 1, 1)
// const overlayMaterial = new THREE.ShaderMaterial({
// 	transparent: true,
// 	uniforms: {
// 		uAlpha: {
// 			value: 1
// 		}
// 	},
// 	vertexShader: `
// 			void main()
// 			{
// 				gl_Position =  gl_Position = vec4(position, 1.0);
// 			}
// 		`,
// 	fragmentShader: `
// 			uniform float uAlpha;
// 			void main()
// 			{
// 				gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
// 			}
// 		`
// })
// const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
// scene.add(overlay)

/**
 * Loaders
 */
let sceneReady = false
const loadingBarElement = document.querySelector('.loading-bar')
const loadingManager = new THREE.LoadingManager(
	() => {
	// 	window.setTimeout(() => {
	// 			// gsap.to(overlayMaterial.uniforms.uAlpha, {
	// 			// 	duration: 3,
	// 			// 	value: 0,
	// 			// 	delay: 1
	// 			// })
	// 			loadingBarElement.classList.add('ended')
	// 			loadingBarElement.style.transform = ''
	// 		}, 500),
	// 		window.setTimeout(() => {
	// 			sceneReady = true
	// 		}, 2000)
	// },

	// Progress
	(itemUrl, itemsLoaded, itemsTotal) => {
		const progressRatio = itemsLoaded / itemsTotal
		loadingBarElement.style.transform = `scaleX(${progressRatio})`
	}
	})
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')
const gltfLoader = new GLTFLoader(loadingManager)
gltfLoader.setDRACOLoader(dracoLoader)



// // test
// const geometry = new THREE.PlaneGeometry(20,20);
// // マテリアルを作成
// const material = new THREE.MeshStandardMaterial({color: 0x6699FF, roughness:0.5});
// material.emissive = 0xff0000
// material.emissiveMap=emiMap
// material.transparent=true
// material.emissiveIntensity=70.2
// // メッシュを作成
// const box= new THREE.Mesh(geometry, material);
// // 3D空間にメッシュを追加
// scene.add(box);
// box.position.set(0,-10,-20)


//model
let objects = []
let atmosObjects = []
let atmosBase =[]
gltfLoader.load(
	'/models/1BlockWorld_1006.glb',
	(gltf) => {
		const model = gltf.scene
		model.castShadow = true
		model.receiveShadow = true
		model.scale.set(9, 9, 9)
		// <<< Tahomi Modified
		// model.position.set(1, -15, -50)
		// model.position.set(5, -10, -40)
		model.position.set(0, 0, 0)
		// Tahomi Modified >>>

		if (isMobile == true) {
			model.scale.set(4.3, 4.3, 4.3)
			model.position.set(2, 0, 0)
		}

		model.traverse((child) => {
			if (child.isMesh) {
				objects.push(child)
				child.castShadow = true
				child.receiveShadow = true
               

				const uv1Array = child.geometry.getAttribute("uv").array;

				// Use this array to create new attribute named `uv2`
				child.geometry.setAttribute('uv2', new THREE.BufferAttribute(uv1Array, 2));
				// console.log(child)
			}
			// const test = gltf.scene.getObjectByName('neon',true)
			// atmos.layers.set(2)
			const atmosExterior = gltf.scene.getObjectByName('Plane001', true)
			const atmosbase = gltf.scene.getObjectByName('BaseCircle',true)
			console.log(child)
			atmosObjects.push(atmosExterior)
			atmosBase.push(atmosbase)
			// comeSoon.material.emissive=0xA5FFEF
			// // comeSoon.material.emissiveMap =emiMap
			// comeSoon.material.emissiveIntensity=80
			// atmosModel = gltf.scene.getObjectByName( "Stage9", true );
			// atmosObjects.push(atmosModel)
			// stage.material = new THREE.MeshBasicMaterial
			// stage.material.color.setHex(0x00ffff)


		})

		scene.add(model)
		// updateAllMaterials()

	},
	// console.log(objects)

)
// individualfloor
console.log(atmosObjects)

//fog

/**
 * Particles
 */
// Geometry
const particlesGeometry = new THREE.BufferGeometry()
const count = 120

const positions = new Float32Array(count * 3)


for (let i = 0; i < count * 3; i++) {
	positions[i] = (Math.random() - 0.5) * 20

}

particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))


// Material
const particlesMaterial = new THREE.PointsMaterial()

particlesMaterial.size = Math.floor(Math.random() * 50) + 40
particlesMaterial.sizeAttenuation = true

particlesMaterial.color = new THREE.Color('#ffffff')

particlesMaterial.transparent = true
particlesMaterial.opacity = 0.07
particlesMaterial.alphaMap = particleTexture
// particlesMaterial.alphaTest = 0.01
particlesMaterial.depthTest = false
particlesMaterial.depthWrite = false
particlesMaterial.blending = THREE.AdditiveBlending



// Points
const particles = new THREE.Points(particlesGeometry, particlesMaterial)
scene.add(particles)
particles.position.set(0, -10, -50)
particles.scale.set(30, 10, 10)


//light
// filllight

const Light1 = new THREE.DirectionalLight(0xF9D8A6, 0.01);
// // const Light1 = new THREE.DirectionalLight(0xFFB717, 10);
Light1.position.set(0, 50, -120)
// Light1.shadow.mapSize.set(1024, 1024)
// Light1.shadow.normalBias = 0.05
// // Light1.castShadow = true
scene.add(Light1)

//  const helper1 = new THREE.DirectionalLightHelper(Light1, 5);
// scene.add(helper1);
// Light1.target.position.set(60, 0, 30);

// gui.add(Light1, 'intensity').min(0).max(20).step(0.001).name('fillLightIntensity')

// // rimlight

const Light2 = new THREE.DirectionalLight(0xDEFCFF, 4.945)

Light2.position.set(10, 40, 80)
// Light2.shadow.mapSize.set(1024, 1024)
Light2.shadow.normalBias = 0.05
// Light2.castShadow = false
scene.add(Light2)
// const helper3 = new THREE.DirectionalLightHelper(Light2, 5); scene.add(helper3);


// gui.add(Light2, 'intensity').min(0).max(20).step(0.001).name('rimlightIntensity')

/**
 * Sizes
 */
const sizes = {
	width: window.innerWidth,
	height: window.innerHeight
}

window.addEventListener('resize', () => {
	// Update sizes
	sizes.width = window.innerWidth
	sizes.height = window.innerHeight

	// Update camera
	camera.aspect = sizes.width / sizes.height
	camera.updateProjectionMatrix()

	// Update renderer
	renderer.setSize(sizes.width, sizes.height)
	renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})
/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(60, sizes.width / sizes.height, 1, 200)
// <<< Tahomi Modified
// camera.position.set(0, 10, 20)
camera.position.set(0, 0, 80)
// Tahomi modified >>>
scene.add(camera)


// Controls
const controls = new OrbitControls(camera, canvas)
controls.maxPolarAngle = Math.PI * 0.395
controls.minPolarAngle = -Math.PI * 0.395

// controls.minDistance = 0.0
controls.maxDistance = 250.0
// <<< Tahomi Modified
// controls.minAzimuthAngle = -Math.PI / 6 // radians
// controls.maxAzimuthAngle = Math.PI / 6 // radians
// Tahomi Modified >>>
controls.enableDamping = true
controls.enableZoom = false

controls.dampingFactor = 1
// controls.rotateSpeed = 0.4
controls.rotateSpeed = 0.6
// controls.minzoom=170.0
// controls.maxZoom=-200.0

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
	canvas: canvas,
	antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))



renderer.shadowMap.type = THREE.PCFShadowMap
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.ReinhardToneMapping
renderer.toneMappingExposure = 2



//postprocess

//postprocessing
let RenderTargetClass = null

if (renderer.getPixelRatio() === 1 && renderer.capabilities.isWebGL2) {
	RenderTargetClass = THREE.WebGLMultisampleRenderTarget

} else {
	RenderTargetClass = THREE.WebGLRenderTarget
}

const renderTarget = new RenderTargetClass(
	800,
	600, {
		minFilter: LinearFilter,
		magFilter: LinearFilter,
		format: RGBAFormat,
		encoding: THREE.sRGBEncoding
	}
)
const effectComposer = new EffectComposer(renderer, renderTarget)
effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
effectComposer.setSize(sizes.width, sizes.height)


const renderPass = new RenderPass(scene, camera)
effectComposer.addPass(renderPass)


const unrealBloomPass = new UnrealBloomPass()

unrealBloomPass.strength = .12
unrealBloomPass.radius = 1.4
unrealBloomPass.threshold = .5
unrealBloomPass.selectedObjects =atmosObjects[1]
unrealBloomPass.scene=scene
unrealBloomPass.camera=camera
effectComposer.addPass(unrealBloomPass)


// gui.add(unrealBloomPass, 'enabled').name('unrealBloomPass enabled')

// gui.add(unrealBloomPass, 'strength').min(0).max(2).step(.001)
// gui.add(unrealBloomPass, 'radius').min(0).max(2).step(.001)
// gui.add(unrealBloomPass, 'threshold').min(0).max(1).step(.001)

const bokehPass = new BokehPass(scene, camera, {
	focus: 50.0,
	aperture: 0.05,
	maxblur: 0.002,
	width: sizes.width,
	height: sizes.height
})
bokehPass.renderToScreen = true
bokehPass.needsSwap = true;
// gui.add(bokehPass, 'enabled').name('bokehpassenabled')
// gui.add(bokehPass.uniforms.maxblur, 'value').min(0).max(1).step(.0001).name('maxblur')
effectComposer.addPass(bokehPass)

//antialias
if (renderer.getPixelRatio() === 1 && !renderer.capabilities.isWebGL2) {
	const smaaPass = new SMAAPass()
	effectComposer.addPass(smaaPass)
}


//interactionpoint
// <<< Tahomi Modified
// const atmosLight = new THREE.SpotLight(0x00FFFB,100,100,Math.PI/6,1,1);
// const atmosLight = new THREE.SpotLight(0xFFEFB8,100,100,Math.PI/6,1,1);
const atmosLight = new THREE.SpotLight(0xFFE99B,100,100,Math.PI/6,1,1);
// Tahomi Modified
// // const Light1 = new THREE.DirectionalLight(0xFFB717, 10);
// <<< Tahomi Modified
// atmosLight.position.set(-80, 5, -50)
atmosLight.position.set(-80,20, 50)
// Tahomi Modified >>>

// Light1.shadow.mapSize.set(1024, 1024)
// Light1.shadow.normalBias = 0.05
// // Light1.castShadow = true
scene.add(atmosLight)
atmosLight.layers.set(2)

// const rectLightHelper = new THREE.RectAreaLightHelper( atmosLight);
// atmosLight.add( rectLightHelper );
//raycastersettings
const mouse = new THREE.Vector2()
canvas.addEventListener('mousemove', handleMouseMove);

// マウスを動かしたときのイベント
function handleMouseMove(event) {
	const element = event.currentTarget;
	// canvas要素上のXY座標
	const x = event.clientX - element.offsetLeft;
	const y = event.clientY - element.offsetTop;
	// canvas要素の幅・高さ
	const w = element.offsetWidth;
	const h = element.offsetHeight;

	// -1〜+1の範囲で現在のマウス座標を登録する
	mouse.x = (x / w) * 2 - 1;
	mouse.y = -(y / h) * 2 + 1;
}
const raycaster = new THREE.Raycaster();



/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () => {
	const elapsedTime = clock.getElapsedTime()

	// // Update particles
	for (let i = 0; i < count; i++) {
		let i3 = i * 4

		const x = particlesGeometry.attributes.position.array[i3]
		particlesGeometry.attributes.position.array[i3 + 1] = Math.cos((elapsedTime + x) / 8)
	}
	particlesGeometry.attributes.position.needsUpdate = true

	
	// Set the raycaster


	//raycaster
	raycaster.setFromCamera(mouse, camera);

	// その光線とぶつかったオブジェクトを得る
	const intersects = raycaster.intersectObjects(atmosObjects);

	if (intersects.length > 0) {
		// ぶつかったオブジェクトに対してなんかする
		console.log('hit')
		camera.layers.enable(2)
		
	}else{
		camera.layers.disable(2)
	}
	// Update controls
	controls.update()

	//stats

	// stats.begin()
	// stats.end()



	// // Render
	 renderer.render(scene, camera)
	//postprocess

	effectComposer.render()
	// Call tick again on the next frame
	window.requestAnimationFrame(tick)
}

tick()