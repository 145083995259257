import logoWhite from '../assets/image/logoW.png'
import {
    gsap
} from "gsap";

//blocker
const blocker = document.querySelector('#entrance')
const landEnter = document.querySelector('#blockerBrake')
window.addEventListener('load',()=>{
    const tl= gsap.timeline()
    tl.fromTo(".blocker-cont",1.2, {
        skewY: -4,
        y: 100,
        opacity: 0,
        ease: "Expo.easeOut",
        stagger: 0.1
    }, {
        opacity:1,
        skewY:0,
        y:0,
    })
})
landEnter.addEventListener('click', ()=>{
    gsap.to(blocker,.8,{
        skewX:20,
        x:'110%',
        y:'-10%',
        opacity:0,
        ease: "Expo.easeInOut"

    })
})
const logoW =document.querySelectorAll('#logoW')

for (let i = 0; i < logoW.length; i++) {
    const logoWImg = document.createElement('img')
    logoWImg.src = logoWhite
    logoW[i].appendChild(logoWImg)
 }